import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { EnergyUtils } from '@app/energy/utils/energy.utils';
import { FileInput } from '@app/form/interfaces/file-input';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { NotificationLabel } from '@app/notification/enums/notification-label.enum';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import {
    ArrayUtils,
    Asset,
    AuditUtils,
    DbUtils,
    EnergyMetersState,
    FileUtils,
    HttpUtils,
    Mimetypes,
    MoveTransactionType,
    ObjectUtils,
    RxjsComponent,
    skipWhileNull,
} from '@smooved/core';
import { TranslateService } from '@ngx-translate/core';
import {
    ButtonAppearance,
    ButtonSize,
    ConfirmModalConfig,
    ElectricityForm,
    ElectricityFormFields,
    GasMetersForm,
    MeterReadingsElectricityComponent,
    MeterReadingsGasComponent,
    ModalSandbox,
    NotificationSandbox,
    SvgIllustration,
    UiContext,
    gasControlNames,
} from '@smooved/ui';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { BehaviorSubject, Observable, combineLatest, concat, last, map, startWith, take, takeUntil } from 'rxjs';
import { FilePreviewModal } from '../file-preview/file-preview.modal';
import { EnergyMetersInfoForm, meterInfoFields } from './energy-meters-info.constants';

@Component({
    selector: 'smvd-app-energy-meters-info-modal',
    templateUrl: 'energy-meters-info.modal.html',
    styleUrls: ['energy-meters-info.modal.scss'],
})
export class EnergyMetersInfoModal extends RxjsComponent implements OnInit, AfterViewInit {
    @ViewChild(MeterReadingsElectricityComponent, { static: false }) meterReadingsElectricity: MeterReadingsElectricityComponent;
    @ViewChild(MeterReadingsGasComponent, { static: false }) meterReadingsGas: MeterReadingsGasComponent;

    @Output() public success = new EventEmitter<Move>();

    public readonly meterInfoForm = meterInfoFields;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public readonly uiContext = UiContext;
    public readonly svgIllustration = SvgIllustration;
    public readonly EnergyMetersState = EnergyMetersState;

    public readonly form: FormGroup = this.formBuilder.group({
        [meterInfoFields.MovingDate]: [null, Validators.required],
        [meterInfoFields.ElectricityMeters]: null,
        [meterInfoFields.GasMeters]: null,
        [meterInfoFields.MeterReadingsTakeover]: false,
    });
    public transfereeMove$: Observable<Move>;
    public transactionType$: Observable<MoveTransactionType>;
    public canConfirmMeters$: Observable<boolean>;
    public removedFiles: File[] = [];

    public uploader: FileUploader;
    public readonly allowedMimeTypes = [Mimetypes.Jpeg, Mimetypes.Png, Mimetypes.Pdf];

    private readonly allowedFileTypes = ['image', 'pdf'];
    private readonly maxFileSize = 10; // Max size in MB
    private readonly hasAssetsSubject = new BehaviorSubject<boolean>(false);
    private readonly isConfirmedSubject = new BehaviorSubject<boolean>(false);

    constructor(
        public readonly moveSandbox: MoveSandbox,
        private readonly formBuilder: FormBuilder,
        private readonly uiSandbox: AppUiSandbox,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly translate: TranslateService,
        private readonly modalSandbox: ModalSandbox,
        private readonly http: HttpClient,
        private readonly dialogRef: MatDialogRef<EnergyMetersInfoModal>
    ) {
        super();
    }

    public ngOnInit(): void {
        this.uploader = new FileUploader({
            url: '',
            disableMultipart: true,
            formatDataFunctionIsAsync: true,
            allowedFileType: this.allowedFileTypes,
            allowedMimeType: this.allowedMimeTypes,
            maxFileSize: FileUtils.convertMegaBytesToBytes(this.maxFileSize),
            formatDataFunction: async (item) => {
                return new Promise((resolve, reject) => {
                    resolve({
                        name: item._file.name,
                        length: item._file.size,
                        contentType: item._file.type,
                        date: new Date(),
                    });
                });
            },
        });

        this.transfereeMove$ = this.moveSandbox.move$.pipe(map(this.getTransfereeMove));
        this.transactionType$ = this.transfereeMove$.pipe(map(this.mapTransactionType));

        this.transfereeMove$.pipe(take(1), skipWhileNull()).subscribe((move) => {
            this.formFactory(move);
            this.handleAssetsInit(move);
        });

        this.canConfirmMeters$ = combineLatest([
            this.form.valueChanges.pipe(startWith(this.form.value)),
            this.hasAssetsSubject.asObservable(),
            this.isConfirmedSubject.asObservable(),
        ]).pipe(
            map(
                ([_, hasAssets, isConfirmed]) =>
                    (hasAssets || MoveUtils.meterComplete(this.mapEnergyMetersInfo(this.form.getRawValue()))) && !isConfirmed
            )
        );

        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.onEditForm);

        this.syncMovingDates();
    }

    public ngAfterViewInit(): void {
        this.transfereeMove$.pipe(take(1), skipWhileNull()).subscribe((move) => {
            if (move?.track.energyMeterReadings.energyMetersState === EnergyMetersState.Processed) return;
            this.meterReadingsElectricity.electricityForm
                .get(ElectricityFormFields.Active)
                .valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe(this.setGasToggle);
            this.meterReadingsGas.gasForm
                .get(gasControlNames.active)
                .valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe(this.setElectricityToggle);

            this.setElectricityToggle(this.meterReadingsGas.gasForm.get(gasControlNames.active).value);
            this.setGasToggle(this.meterReadingsElectricity.electricityForm.get(ElectricityFormFields.Active).value);
        });
    }

    public onSubmit(): void {
        if (this.form.invalid) return;
        this.patchMove();
    }

    public confirmAndSubmit(): void {
        if (this.form.invalid) return;
        const data: ConfirmModalConfig = {
            data: this.translate.instant('ENERGY.ENERGY_METER_INFO.COMPLETED.CONFIRM_INFO'),
            header: this.translate.instant('ENERGY.METER_READING.CONFIRM.TITLE'),
            cancelLabel: 'ENERGY.METER_READING.CONFIRM.GO_BACK',
            submitLabel: 'COMMON.SUBMIT',
        };

        const onClose = (confirm: boolean) => {
            if (!confirm) return;
            this.form.get(meterInfoFields.MeterReadingsTakeover).patchValue(true, { emitEvent: false });
            this.patchMove();
        };

        this.modalSandbox.openConfirmModal(data, onClose, data, onClose);
    }

    public openFileDetail(file: FileItem): void {
        /**
         *  file.rawFile can be two types:
         *  1 - blob, when it's coming from uploader (selected in input[type=file]);
         *  2 - FileInput, when file is already in Database. In this case, fileInput is set on rawFile of FileItem;
         *
         * */
        const fileType: 'FileInput' | 'Blob' = !!(file.file.rawFile as unknown as FileInput)?.location ? 'FileInput' : 'Blob';

        if (fileType === 'FileInput') {
            const fileInput = file.file.rawFile as unknown as FileInput;
            if (fileInput.mime === Mimetypes.Pdf) {
                this.http
                    .get(fileInput.location, { responseType: 'blob' })
                    .pipe(take(1))
                    .subscribe((blob) => FileUtils.downloadAsset(blob, fileInput.name));
                return;
            }
        }

        if (fileType === 'Blob') {
            const fileInput = file.file.rawFile as File;
            if (fileInput.type === Mimetypes.Pdf) {
                FileUtils.downloadAsset(fileInput, file.file.name);
                return;
            }
        }

        /** If not a PDF, open modal to preview image */
        this.modalSandbox.openModal(FilePreviewModal, { data: file }, null, FilePreviewModal, { data: file }, null);
    }

    public onFileSelected(list: FileList): void {
        for (const item of Array.from(list)) {
            if (item.size > FileUtils.convertMegaBytesToBytes(this.maxFileSize)) {
                this.notificationSandbox.error('ENERGY.INPUT.FILE_ERROR.SIZE', { maxFileSize: this.maxFileSize });
                break;
            }

            if (!this.allowedMimeTypes.includes(item.type as Mimetypes)) {
                this.notificationSandbox.error('ENERGY.INPUT.FILE_ERROR.TYPE', {
                    allowedTypes: this.allowedMimeTypes.map((mime) => ArrayUtils.last(mime.split('/'))).join(', '),
                });
                break;
            }
        }
        this.onEditForm();
    }

    public removeAsset(item: FileItem): void {
        this.removedFiles.push(item.file.rawFile as File);
        this.uploader.removeFromQueue(item);
        this.onEditForm();
    }

    public patchMove(): void {
        this.uiSandbox.showLoadingOverlay();
        this.transfereeMove$
            .pipe(take(1))
            .subscribe((move) =>
                this.moveSandbox.patchProperty(
                    '',
                    this.getPatchData(),
                    true,
                    (updatedMove) => this.uploadEnergyAssets(updatedMove || move),
                    false,
                    move,
                    false
                )
            );
    }

    protected onMetersUpdateSuccess(updatedMove: Move): void {
        if (updatedMove) this.notificationSandbox.success(NotificationLabel.MovePatchSuccess);
        this.transfereeMove$.subscribe((move) => this.success.emit(updatedMove || move));
        this.moveSandbox.setMoveState(updatedMove);
        this.dialogRef.close(updatedMove);
    }

    protected onUploadSuccess = (patchedMoveAfterAssets: Move): void => {
        this.uiSandbox.hideLoadingOverlay();
        this.notificationSandbox.success(NotificationLabel.MovePatchSuccess);
        this.success.emit(patchedMoveAfterAssets);
        this.moveSandbox.setMoveState(patchedMoveAfterAssets);
        this.dialogRef.close(patchedMoveAfterAssets);
    };

    private syncMovingDates(): void {
        this.form
            .get(meterInfoFields.MovingDate)
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((movingDate) => {
                const electricitymetersData = this.form.get(meterInfoFields.ElectricityMeters).value;
                if (!electricitymetersData.active) return;
                this.form.get(meterInfoFields.ElectricityMeters).patchValue({ ...electricitymetersData, movingDate }, { emitEvent: false });
            });

        this.form
            .get(meterInfoFields.ElectricityMeters)
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe(({ movingDate: automaticMovingDate }) => {
                const movingDate = this.form.get(meterInfoFields.MovingDate).value;
                if (!automaticMovingDate || movingDate === automaticMovingDate) return;
                this.form.get(meterInfoFields.MovingDate).patchValue(automaticMovingDate, { emitEvent: false });
            });
    }

    private setHasAssets(): void {
        this.hasAssetsSubject.next(!ArrayUtils.isEmpty(this.uploader.queue));
    }

    private onEditForm = (): void => {
        /** If user edits form, takeover and state confirmed aren't pristine anymore. It requires new confirmation */
        this.form.get(meterInfoFields.MeterReadingsTakeover).patchValue(false, { emitEvent: false });
        this.isConfirmedSubject.next(false);
        this.form.markAsDirty();
        this.setHasAssets();
    };

    private formFactory = (move: Move): void => {
        this.form.patchValue({
            [meterInfoFields.MovingDate]: move.movingDate,
            [meterInfoFields.ElectricityMeters]: this.mapElectricityMeterReadingsForm(move),
            [meterInfoFields.GasMeters]: this.mapGasMeterReadingsForm(move),
            [meterInfoFields.MeterReadingsTakeover]: AuditUtils.getValue(move.legal?.meterReadingsTakeover),
        });

        this.isConfirmedSubject.next(move?.track?.energyMeterReadings?.energyMetersState === EnergyMetersState.Confirmed);

        if (move?.track?.energyMeterReadings?.energyMetersState === EnergyMetersState.Processed) {
            this.form.get(meterInfoFields.MovingDate).disable();
            this.form.get(meterInfoFields.ElectricityMeters).disable();
            this.form.get(meterInfoFields.GasMeters).disable();
        }
    };

    private handleAssetsInit(move: Move): void {
        const existingFiles = this.uploader.queue.map((item) => item._file as File);
        const uploadedAssets = [...(move.energyDocumentAssets ?? []), ...(move.energyMeterReadingAssets ?? [])] as unknown as File[];

        const filesToAdd = uploadedAssets.filter(
            (file) => !existingFiles.some((existingFile) => existingFile.name === file.name && existingFile.size === file.size)
        );

        if (!ArrayUtils.isEmpty(filesToAdd) && this.uploader.queue.length === 0) {
            this.uploader.addToQueue(filesToAdd);
        }

        this.setHasAssets();
    }

    private getTransfereeMove = (move: Move): Move => {
        const { transferee } = MoveUtils.getMovers(move);
        return transferee;
    };

    private getPatchData(): Partial<Move> {
        const patch = this.mapEnergyMetersInfo(this.form.getRawValue());
        ObjectUtils.removeEmpty(patch, true);
        return patch;
    }

    private mapElectricityMeterReadingsForm(move: Move): ElectricityForm {
        const {
            movingDate,
            energyOffer,
            eanCodeElectricity,
            electricitySingleMeterReading,
            electricityDoubleDayMeterReading,
            electricityDoubleNightMeterReading,
            electricityExclusiveNight,
            electricityDoubleExclusiveNightMeterReading,
            energyDigitalMeterReadings,
        } = move;
        return {
            active: EnergyUtils.hasElectricity(move.energyOffer?.energyType) || !!MoveUtils.energyTransferSelected(move),
            meterType: EnergyUtils.meterType(move),
            eanCodeElectricity,
            electricitySingleMeterReading,
            electricityDoubleDayMeterReading,
            electricityDoubleNightMeterReading,
            exclusiveNight: electricityExclusiveNight,
            electricityExclusiveNightMeterReading: electricityDoubleExclusiveNightMeterReading,
            automaticDigitalReadings: energyDigitalMeterReadings?.automatic,
            movingDate,
            consumptionDayMeterReading: energyDigitalMeterReadings?.consumption?.day,
            consumptionNightMeterReading: energyDigitalMeterReadings?.consumption?.night,
            solarPanels: energyOffer?.hasSolarPanels ?? true,
            injectionDayMeterReading: energyDigitalMeterReadings?.injection?.day,
            injectionNightMeterReading: energyDigitalMeterReadings?.injection?.night,
        };
    }

    private mapTransactionType = (move: Move): MoveTransactionType => MoveUtils.getMoveTransactionType(move);

    private mapGasMeterReadingsForm(move: Move): GasMetersForm {
        return {
            active: MoveUtils.showMeterReadingGas(move) || !!MoveUtils.energyTransferSelected(move),
            eanCodeGas: move.eanCodeGas,
            gasMeterReading: move.gasMeterReading,
        };
    }

    private mapEnergyType = (electricityActive: boolean, gasActive: boolean): EnergyType =>
        !electricityActive ? EnergyType.Gas : !gasActive ? EnergyType.Electricity : EnergyType.Both;

    private mapEnergyMetersInfo = ({
        movingDate,
        electricityMeters,
        gasMeters,
        meterReadingsTakeover,
    }: EnergyMetersInfoForm): Partial<Move> => {
        const {
            active: electricityActive,
            meterType,
            eanCodeElectricity,
            electricitySingleMeterReading,
            electricityDoubleDayMeterReading,
            electricityDoubleNightMeterReading,
            automaticDigitalReadings,
            consumptionDayMeterReading,
            consumptionNightMeterReading,
            solarPanels,
            injectionDayMeterReading,
            injectionNightMeterReading,
            exclusiveNight,
            electricityExclusiveNightMeterReading,
        } = electricityMeters || {};
        const { active: gasActive, eanCodeGas, gasMeterReading } = gasMeters || {};
        const result = {
            movingDate,
            eanCodeGas,
            gasMeterReading,
            eanCodeElectricity,
            electricitySingleMeterReading,
            electricityDoubleDayMeterReading,
            electricityDoubleNightMeterReading,
            energyDigitalMeterReadings: {
                automatic: automaticDigitalReadings,
                consumption: {
                    day: consumptionDayMeterReading,
                    night: consumptionNightMeterReading,
                },
                injection: {
                    day: injectionDayMeterReading,
                    night: injectionNightMeterReading,
                },
            },
            electricityExclusiveNight: exclusiveNight,
            electricityDoubleExclusiveNightMeterReading: electricityExclusiveNightMeterReading,
            energyOffer: {
                meterType,
                hasSolarPanels: solarPanels,
                energyType: this.mapEnergyType(electricityActive, gasActive),
            },
            legal: {
                meterReadingsTakeover,
            },
            energyReadingsConfirmedByRealEstateAgent: meterReadingsTakeover,
        };
        ObjectUtils.removeEmpty(result);
        return result;
    };

    private uploadEnergyAssets(move: Move): void {
        const filesToAdd = this.getFilesToAdd(move);
        const filesToRemove = this.getFilesToRemove(move);

        const httpCalls = [...this.getUploadCalls(filesToAdd, move), ...this.getDeleteCalls(filesToRemove, move)];

        if (!ArrayUtils.isEmpty(httpCalls)) {
            this.uiSandbox.showLoadingOverlay();

            concat(...httpCalls)
                .pipe(last())
                .subscribe({ next: this.onUploadSuccess, error: this.onUploadFail });
        } else {
            this.onMetersUpdateSuccess(move);
        }
    }

    private getFilesToAdd(move: Move): File[] {
        const uploadedAssets = [...(move?.energyDocumentAssets ?? []), ...(move?.energyMeterReadingAssets ?? [])];
        return this.uploader.queue
            .map((item) => item._file)
            .filter((file) => !uploadedAssets.some((asset) => asset.key === (file as unknown as Asset).key));
    }

    private getFilesToRemove(move: Move): File[] {
        const uploadedAssets = [...(move?.energyDocumentAssets ?? []), ...(move?.energyMeterReadingAssets ?? [])];
        return this.removedFiles?.filter((file) => uploadedAssets?.some((asset) => (file as unknown as Asset).key === asset?.key));
    }

    private getUploadCalls(filesToAdd: File[], move: Move): Observable<any>[] {
        if (ArrayUtils.isEmpty(filesToAdd)) return [];

        const formData = HttpUtils.addFiles(filesToAdd);
        return [this.moveSandbox.uploadEnergyMeterReadingAsset(DbUtils.getStringId(move), formData)];
    }

    private getDeleteCalls(filesToRemove: File[], move: Move): Observable<any>[] {
        if (ArrayUtils.isEmpty(filesToRemove)) return [];
        return filesToRemove.map((file) => this.moveSandbox.deleteEnergyAsset(DbUtils.getStringId(move), (file as unknown as Asset).key));
    }

    private onUploadFail = (): void => this.uiSandbox.hideLoadingOverlay();

    private setElectricityToggle = (gasValue: boolean) =>
        this.meterReadingsElectricity.electricityForm
            .get(ElectricityFormFields.Active)
            [gasValue ? 'enable' : 'disable']({ emitEvent: false });

    private setGasToggle = (electricityValue: boolean) =>
        this.meterReadingsGas.gasForm.get(gasControlNames.active)[electricityValue ? 'enable' : 'disable']({ emitEvent: false });
}
