import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { formControlNames, formGroupNames } from '@app/external-influencer/constants/form.constants';
import { ExternalInfluencerService } from '@app/external-influencer/services/external-influencer.service';
import { ImageSandbox } from '@app/ui/image/sandboxes/image.sandbox';
import { ButtonAppearance, UiContext } from '@smooved/ui';

@Component({
    selector: 'app-external-influencer-configure-header',
    templateUrl: './external-influencer-configure-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalInfluencerConfigureHeaderComponent implements AfterViewInit {
    public formControlNames = formControlNames;
    public formGroupNames = formGroupNames;
    public buttonAppearance = ButtonAppearance;
    public uiContext = UiContext;

    constructor(
        private readonly imageSandbox: ImageSandbox,
        private readonly cdr: ChangeDetectorRef,
        private readonly ngZone: NgZone,
        public readonly externalInfluencerService: ExternalInfluencerService
    ) {}

    ngAfterViewInit(): void {
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.ngZone.run(() => {
                    this.cdr.detectChanges();
                });
            }, 0);
        });
    }

    public openImagePicker(): void {
        this.imageSandbox.openImagePicker((image) => {
            this.externalInfluencerService.form.get(formGroupNames.header).get(formControlNames.cover).patchValue(image);
            this.cdr.markForCheck(); // Notify Angular to check this component
        });
    }

    public getImagePreview(): string {
        this.cdr.markForCheck(); // Notify Angular to check this component
        const image = this.externalInfluencerService.form.get(formGroupNames.header).get(formControlNames.cover).value;
        if (image instanceof Blob) {
            return URL.createObjectURL(image);
        } else if (image) {
            return image;
        }
        return null;
    }
}
